/* eslint-disable react/prop-types */
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

function Title({ name, image }) {
  return (
    <MDBox display="flex" alignItems="center" size="sm" maxWidth="80%" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default Title;
