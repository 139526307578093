import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

import logo from "assets/images/logo.png";

function ComingSoon() {
  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <img src={logo} alt="logo" width={200} height={200}></img>
      <MDTypography variant="h1" color="main">
        Coming Soon...
      </MDTypography>
    </MDBox>
  );
}

export default ComingSoon;
