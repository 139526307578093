/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "assets/images/logo.png";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setIsLoggedIn } from "context";
import { useMaterialUIController } from "context";
import { Helmet } from "react-helmet";
import { Oval } from "react-loader-spinner";
import { Box } from "@mui/material";

function Basic() {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (username == null || password == null) {
      alert("Please enter username and password");
    } else {
      setLoading(true);
      try {
        const response = await axios.post(`${SERVER_URL}/authenticate`, {
          username: username,
          password: password,
        });
        const tokenL = response.data.token;
        const roles = response.data.roles;
        const expiry = response.data.expiry;
        localStorage.setItem("token", tokenL);
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("expiry", expiry);
        setIsLoggedIn(dispatch, true);
        navigate("/dashboard");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error in signing in ==>", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign In</title>
        <link rel="icon" href={logo} />
      </Helmet>

      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSignIn}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Username"
                  fullWidth
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  sign in
                </MDButton>
              </MDBox>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {loading && (
                  <Oval
                    visible={true}
                    height="35"
                    width="35"
                    color="#2196F3"
                    secondaryColor="#2196F3"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </Box>
              <MDBox mt={3} mb={1} textAlign="center"></MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default Basic;
