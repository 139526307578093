import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DownloadUserByDateOfJoining from "GoComponents/Users/DownloadUserByDateOfJoining";
import ReferralList from "GoComponents/Users/ReferralList";
import TopReferrals from "GoComponents/Users/TopReferrals";
import UserByEmail from "GoComponents/Users/UserByEmail";
import NotLoggedIn from "layouts/authentication/not-logged-in";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

function Users() {
  const [api, setApi] = useState("Referral list");
  const [controller, dispatch] = useMaterialUIController();
  const { isLoggedIn } = controller;

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }

  return (
    <>
      <Helmet>
        <title>Users</title>
        <link rel="icon" href="assets/images/logo.png" />
      </Helmet>
      <DashboardLayout>
        <Autocomplete
          disableClearable
          value={api}
          options={["Referral list", "Top referrals", "Find by email", "Download by joining date"]}
          onChange={(event, newValue) => {
            setApi(newValue);
          }}
          size="medium"
          sx={{ width: "20rem" }}
          renderInput={(params) => <MDInput {...params} />}
        />
        {api == "Referral list" && <ReferralList />}
        {api == "Top referrals" && <TopReferrals />}
        {api == "Find by email" && <UserByEmail />}
        {api == "Download by joining date" && <DownloadUserByDateOfJoining />}
      </DashboardLayout>
    </>
  );
}

export default Users;
