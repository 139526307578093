/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from "@mui/material";
import MDButton from "components/MDButton";

const FilterDialog = ({
  open,
  onClose,
  onApplyFilter,
  activeFilter,
  setFiltersOpen,
  recordingNull,
  setRecordingNull,
}) => {
  const [filterType, setFilterType] = useState(activeFilter?.type || "date");
  const [minDate, setMinDate] = useState(activeFilter?.minDate || "");
  const [maxDate, setMaxDate] = useState(activeFilter?.maxDate || "");
  const [filterField, setFilterField] = useState(activeFilter?.field || "");
  const [filterValue, setFilterValue] = useState(activeFilter?.value || "");
  const [filterDataType, setFilterDataType] = useState("string");

  const formatDateForInput = (unixTimestamp) => {
    if (!unixTimestamp) return "";
    const date = new Date(parseInt(unixTimestamp));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleDateChange = (name, date) => {
    const unixTime = new Date(date).getTime().toString();
    if (name === "minDate") setMinDate(unixTime);
    else setMaxDate(unixTime);
  };

  const handleApplyFilter = () => {
    if (filterType === "date") {
      onApplyFilter({ type: "date", minDate, maxDate, recordingNull: recordingNull });
    } else {
      let parsedValue = filterValue;
      if (filterDataType === "number") {
        parsedValue = parseFloat(filterValue);
      } else if (filterDataType === "boolean") {
        parsedValue = filterValue.toLowerCase() === "true";
      }
      onApplyFilter({
        type: "field",
        field: filterField,
        value: parsedValue,
        dataType: filterDataType,
        recordingNull: recordingNull,
      });
    }
    setFiltersOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Filters</DialogTitle>
      <DialogContent>
        <RadioGroup
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <FormControlLabel value="date" control={<Radio />} label="Filter by Date Range" />
          <FormControlLabel value="field" control={<Radio />} label="Filter by Field" />
        </RadioGroup>
        <FormGroup
          sx={{
            mb: "50px",
            mt: "20px",
            ml: "5%",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={recordingNull}
                onChange={() => {
                  setRecordingNull(!recordingNull);
                }}
                color="primary"
              />
            }
            label="Recording is Null"
          />
        </FormGroup>
        {filterType === "date" ? (
          <>
            <TextField
              label="Start Date"
              type="datetime-local"
              value={formatDateForInput(minDate)}
              onChange={(e) => handleDateChange("minDate", e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              label="End Date"
              type="datetime-local"
              value={formatDateForInput(maxDate)}
              onChange={(e) => handleDateChange("maxDate", e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
            />
          </>
        ) : (
          <>
            <TextField
              label="Field"
              value={filterField}
              onChange={(e) => setFilterField(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal" size="medium">
              <InputLabel>Data Type</InputLabel>
              <Select
                value={filterDataType}
                onChange={(e) => setFilterDataType(e.target.value)}
                sx={{
                  padding: "10px",
                }}
              >
                <MenuItem value="string">String</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="boolean">Boolean</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Value"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              fullWidth
              margin="normal"
              type={
                filterDataType === "number"
                  ? "number"
                  : filterDataType === "string"
                  ? "text"
                  : "boolean"
              }
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="secondary">
          {activeFilter != null ? "Clear filter" : "Cancel"}
        </MDButton>
        <MDButton onClick={handleApplyFilter} color="info">
          Apply Filter
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
