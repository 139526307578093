/* eslint-disable react/prop-types */
import { Grid, Box, Typography } from "@mui/material";

const NumberGrid = ({ alreadyCalledNumbers, currentNumber }) => {
  return (
    <Box
      sx={{
        minWidth: "60vw",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
      }}
      spacing={1}
    >
      <Grid
        container
        sx={{
          width: "60vw",
          alignSelf: "end",
        }}
      >
        {Array.from({ length: 90 }, (_, i) => i + 1).map((number) => (
          <Grid
            item
            key={number}
            xs={1.2}
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                border: "1px solid lightgrey",
                width: "100%",
                height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: alreadyCalledNumbers.includes(number)
                  ? "#29BFC2"
                  : number === currentNumber
                  ? "#ffb9b3"
                  : "#f2f5fc",
                fontWeight: "bold",
                margin: "2.5px",
              }}
            >
              <Typography
                variant="h4"
                color={
                  alreadyCalledNumbers.includes(number) || number == currentNumber
                    ? "#fff"
                    : "#A9AAA9"
                }
              >
                {number}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NumberGrid;
