import { useMaterialUIController } from "context";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn } from "context";

function LogOut() {
  const [controller, dispatch] = useMaterialUIController();
  const { isLoggedIn } = controller;

  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    setIsLoggedIn(dispatch, false);
    navigate("/");
  }, []);

  return <div></div>;
}

export default LogOut;
