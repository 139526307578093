import { Box, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { image } from "./data";
import { Oval } from "react-loader-spinner";
import { Helmet } from "react-helmet";

function GetTicket() {
  const [ticketNumber, setTicketNumber] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [token, setToken] = useState(null);
  const [imageData, setImageData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const tokenL = localStorage.getItem("token");
    const evendIdL = localStorage.getItem("eventId");
    setEventId(evendIdL);
    setToken(tokenL);

    // let timer = setTimeout(() => {
    //   setImageData(image);
    // }, 3000);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchImage();
  };

  const fetchImage = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${SERVER_URL}/admin/tambola/getTicket`,
        {
          eventId: eventId,
          ticketNumber: ticketNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.data.message == "Ticket not found") {
        throw new Error("Ticket not found");
      }

      const base64 = btoa(
        new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
      );
      setImageData(`data:image/png;base64,${base64}`);
      setLoading(false);
    } catch (error) {
      if (error == "Ticket not found") {
        alert("Ticket not found");
      }
      setLoading(false);
      console.error("Error fetching image:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Get Tambola Ticket</title>
        <link rel="icon" href="assets/images/logo.png" />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <MDTypography variant="h2" color="text">
            Please enter the Ticket Number to get the Ticket :
          </MDTypography>
          <MDBox mb={1}>
            <MDInput
              type="number"
              label="Enter the ticket number"
              fullWidth
              value={ticketNumber}
              onChange={(e) => setTicketNumber(e.target.value)}
            />
          </MDBox>
          <MDBox mt={1} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
              Get Ticket
            </MDButton>
          </MDBox>
          {loading && (
            <Oval
              visible={true}
              height="50"
              width="50"
              color="#2196F3"
              secondaryColor="#2196F3"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
          {!loading && imageData && imageData.length > 0 && <img src={imageData} alt="Ticket" />}
        </Box>
      </DashboardLayout>
    </>
  );
}

export default GetTicket;
