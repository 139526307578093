import { Autocomplete, Avatar, Box, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
// import ReferralDataTable from "./ReferralDataTable";
import { Title } from "chart.js";
import View from "layouts/events/View";
import DataTable from "./DataTable";
// import DataTable from "GoComponents/Events";

function ReferralList() {
  const [fromPhone, setFromPhone] = useState(null);
  const [referralData, setReferralData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!fromPhone) {
      setError(true);
      return;
    }
    try {
      setError(false);
      setLoading(true);
      const response = await axios.post(
        `${SERVER_URL}/user/referralsList`,
        {
          from: fromPhone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      setReferralData(data.referrals);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const columns = [
    { Header: "ID", accessor: "id", align: "left" },
    { Header: "From", accessor: "from", align: "left" },
    { Header: "Referral ID", accessor: "referralId", align: "center" },
    { Header: "To", accessor: "to", align: "center" },
    { Header: "Profile Image", accessor: "profileImage", align: "center" },
    { Header: "Name", accessor: "name", align: "center" },
    { Header: "Time", accessor: "time", align: "center" },
    { Header: "Attended", accessor: "attended", align: "center" },
  ];

  const rows =
    referralData &&
    referralData.map((d) => ({
      id: d.id,
      from: d.from,
      referralId: d.referralId,
      to: d.to,
      profileImage: <Avatar src={d.toProfileImage} alt={d.toName} sx={{ width: 40, height: 40 }} />,
      time: new Date(parseInt(d.time)).toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }),
      attended: d.hasAttendedSession ? "Yes" : "No",
      name: d.toName,
    }));

  //   ferrals: Array(9) [
  //     {
  //       id: 'd198c4f8-761e-40f2-b027-fdf73e3df03f',
  //       from: '919841278979',
  //       referralId: 'yBYiqy',
  //       to: '917678625090',
  //       time: '1681631941997',
  //       hasAttendedSession: true,
  //       toName: 'ADARSH.AGGARWAL',
  //       toProfileImage: ' ... (length: 54610)'
  //     },
  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSignIn}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="From"
                  fullWidth
                  value={fromPhone}
                  onChange={(e) => setFromPhone(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  View
                </MDButton>
              </MDBox>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {loading && (
                  <Oval
                    visible={true}
                    height="35"
                    width="35"
                    color="#2196F3"
                    secondaryColor="#2196F3"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </Box>
              <MDBox mt={3} mb={1} textAlign="center"></MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      {referralData && !error && (
        <DataTable
          table={{ columns, rows }}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
          canSearch={false}
          pagination={false}
        />
      )}
      {error && (
        <Box>
          <Typography color={"red"}>
            Sorry, Either no referrals for this number or something went wrong
          </Typography>
        </Box>
      )}
    </>
  );
}

export default ReferralList;
