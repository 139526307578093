/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import {
  DialogContent,
  Box,
  TextField,
  Avatar,
  Autocomplete,
  Button,
  Alert,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import JoditEditor from "jodit-react";
import { Oval } from "react-loader-spinner";
// import "jodit/build/jodit.min.css";

const EventDetails = ({
  create,
  createTemplate,
  createFromTemplate,
  eventToView,
  onUpdateEvent,
  onUpdateTemplate,
  onCreateEvent,
  onCreateTemplate,
  updatedFields,
  setUpdatedFields,
  onDeleteEvent,
  isEditable,
  loading,
  error,
}) => {
  const editorConfig = {
    readonly: false, // Enable editing
    uploader: {
      insertImageAsBase64URI: true, // Option to insert images as Base64 URIs
    },
    toolbarButtonSize: "middle",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "image",
      "link",
      "align",
      "undo",
      "redo",
      "|",
      "source",
    ],
  };
  const [editableEvent, setEditableEvent] = useState(eventToView || {});
  const [createableEvent, setCreateableEvent] = useState(createFromTemplate ? eventToView : {});
  const [creatableTemplate, setCreateableTemplate] = useState({});

  const validateData = async () => {
    const myPromise = new Promise((resolve, reject) => {
      if (Object.keys(createableEvent).length == 0) {
        return reject(false);
      }
      if (createableEvent.eventName == undefined || createableEvent.eventName == "") {
        return reject(false);
      }
      if (createableEvent.coverImage == undefined || createableEvent.coverImage == "") {
        return reject(false);
      }
      if (createableEvent.description == undefined || createableEvent.description == "") {
        return reject(false);
      }
      if (createableEvent.expertImage == undefined || createableEvent.expertImage == "") {
        return reject(false);
      }
      if (createableEvent.expertName == undefined || createableEvent.expertName == "") {
        return reject(false);
      }
      if (createableEvent.category == undefined || createableEvent.category == "") {
        return reject(false);
      }
      if (createableEvent.creator == undefined || createableEvent.creator == "") {
        return reject(false);
      }
      if (createableEvent.eventDate == undefined || createableEvent.eventDate == "") {
        return reject(false);
      }
      if (createableEvent.startTime == undefined || createableEvent.startTime == "") {
        return reject(false);
      }
      if (createableEvent.endTime == undefined || createableEvent.endTime == "") {
        return reject(false);
      }
      if (createableEvent.costType == undefined || createableEvent.costType == "") {
        return reject(false);
      }
      if (
        // eslint-disable-next-line prettier/prettier
        createableEvent.costType == "paid" &&
        (createableEvent.cost == undefined || createableEvent.cost == 0)
      ) {
        return reject(false);
      }
      if (createableEvent.seatsLeft == undefined || createableEvent.seatsLeft == 0) {
        return reject(false);
      }
      if (createableEvent.type == undefined || createableEvent.type == "") {
        return reject(false);
      }

      resolve(true);
    });
    return myPromise;
  };

  const handleCreateChange = (e) => {
    let { name, value, type } = e.target;

    if (type == "number") {
      value = parseInt(value, 10);

      if (isNaN(value)) {
        value = 0;
      }
    }
    setCreateableTemplate({ ...creatableTemplate, [name]: value });
    setCreateableEvent({ ...createableEvent, [name]: value });
  };

  const handleChange = (e) => {
    let { name, value, type } = e.target;
    if (type == "number") {
      value = parseInt(value, 10);
      if (isNaN(value)) value = 0;
    }
    setEditableEvent({ ...editableEvent, [name]: value });
    setUpdatedFields({ ...updatedFields, [name]: value });
  };

  const formatDateForInput = (unixTimestamp) => {
    const date = new Date(parseInt(unixTimestamp));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleDateChange = (name, date) => {
    const unixTime = new Date(date).getTime().toString();
    setCreateableTemplate({ ...creatableTemplate, [name]: unixTime });
    setEditableEvent({ ...editableEvent, [name]: unixTime });
    setUpdatedFields({ ...updatedFields, [name]: unixTime });
    setCreateableEvent({ ...createableEvent, [name]: unixTime });
  };

  const handleHtmlChange = (value) => {
    setCreateableTemplate({ ...creatableTemplate, beautifulDescription: value });
    setEditableEvent({ ...editableEvent, beautifulDescription: value });
    setUpdatedFields({ ...updatedFields, beautifulDescription: value });
    setCreateableEvent({ ...createableEvent, beautifulDescription: value });
  };

  const handleUpdateEvent = () => {
    onUpdateEvent();
  };

  const handleDeleteEvent = () => {
    onDeleteEvent();
  };

  const handleCreateEvent = () => {
    onCreateEvent(createableEvent);
  };

  const handleCreateTemplate = () => {
    onCreateTemplate(creatableTemplate);
  };

  if (createTemplate) {
    return (
      <DialogContent>
        <div>
          <TextField
            disabled={!isEditable}
            label="Template Name"
            name="templateName"
            value={creatableTemplate.templateName}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Event Name"
            name="eventName"
            value={creatableTemplate.eventName}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Cover Image"
            name="coverImage"
            value={creatableTemplate.coverImage}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Description"
            name="description"
            value={creatableTemplate.description}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            required={true}
          />
          <p>
            <strong>Beautiful Description:</strong>
          </p>
          <JoditEditor
            value={creatableTemplate.beautifulDescription}
            config={editorConfig}
            tabIndex={1}
            onBlur={(newContent) => handleHtmlChange(newContent)}
          />
          <TextField
            disabled={!isEditable}
            label="Expert Image"
            name="expertImage"
            value={creatableTemplate.expertImage}
            onChange={handleCreateChange}
            fullWidth
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Expert Name"
            name="expertName"
            value={creatableTemplate.expertName}
            onChange={handleCreateChange}
            fullWidth
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Category"
            name="category"
            value={creatableTemplate.category}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Creator"
            name="creator"
            value={creatableTemplate.creator}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <p>
            <strong>Event Date:</strong>
          </p>
          <MDInput
            type="date"
            fullWidth
            // value={creatableTemplate.eventDate && formatDateForInput(creatableTemplate.eventDate)}
            onChange={(e) => handleDateChange("eventDate", e.target.value)}
            margin="normal"
            required={true}
          />
          <p>
            <strong>Start Time:</strong>
          </p>
          <MDInput
            type="datetime-local"
            fullWidth
            onChange={(e) => handleDateChange("startTime", e.target.value)}
            margin="normal"
            required={true}
          />
          <p>
            <strong>End Time:</strong>
          </p>
          <MDInput
            type="datetime-local"
            fullWidth
            onChange={(e) => handleDateChange("endTime", e.target.value)}
            margin="normal"
            required={true}
          />
          <Autocomplete
            options={["free", "paid"]}
            value={creatableTemplate.costType || ""}
            onChange={(event, newValue) => {
              setCreateableTemplate({ ...creatableTemplate, costType: newValue });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Cost Type"
                fullWidth
                margin="normal"
                required={true}
              />
            )}
          />
          <TextField
            disabled={!isEditable}
            label="Cost"
            name="cost"
            type="number"
            value={Number(creatableTemplate.cost)}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Seats Left"
            name="seatsLeft"
            type="number"
            value={Number(creatableTemplate.seatsLeft)}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="occurance"
            name="occurance"
            type="number"
            value={creatableTemplate.occurance}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <Autocomplete
            options={["workshop", "session"]}
            value={creatableTemplate.type || ""}
            onChange={(event, newValue) => {
              setCreateableTemplate({ ...creatableTemplate, type: newValue });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Type"
                fullWidth
                margin="normal"
                required={true}
              />
            )}
          />
          <Autocomplete
            options={["true", "false"]}
            value={
              String(creatableTemplate.isParent) == undefined
                ? ""
                : String(creatableTemplate.isParent)
            }
            onChange={(event, newValue) => {
              setCreateableTemplate({
                ...creatableTemplate,
                isParent: newValue == "true" ? true : false,
              });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Is parent"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Autocomplete
            options={["true", "false"]}
            value={
              String(creatableTemplate.isScheduled) == undefined
                ? ""
                : String(creatableTemplate.isScheduled)
            }
            onChange={(event, newValue) => {
              console.log(newValue);

              setCreateableTemplate({
                ...creatableTemplate,
                isScheduled: newValue == "true" ? true : false,
              });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Is scheduled"
                fullWidth
                margin="normal"
              />
            )}
          />
          <TextField
            disabled={!isEditable}
            label="Cron"
            name="cron"
            value={creatableTemplate.cron || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Same day Event ID"
            name="sameDayEventId"
            value={creatableTemplate.sameDayEventId || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <Box display="flex" justifyContent="center" marginTop="20px">
            <MDButton
              onClick={() => {
                handleCreateTemplate();
              }}
              color="info"
              variant="contained"
            >
              Create Template
            </MDButton>
          </Box>
          {error && !loading && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              Sorry, something went wrong.
            </Alert>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" marginTop="20px">
              <Oval
                visible={true}
                height="35"
                width="35"
                color="#2196F3"
                secondaryColor="#2196F3"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          )}
        </div>
      </DialogContent>
    );
  }

  if (!create && !eventToView) {
    return (
      <DialogContent>
        <p>No event selected</p>
      </DialogContent>
    );
  }

  if (create || createFromTemplate) {
    return (
      <DialogContent>
        <div>
          <TextField
            disabled={!isEditable}
            label="Event Name"
            name="eventName"
            value={createableEvent.eventName}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Cover Image"
            name="coverImage"
            value={createableEvent.coverImage}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Description"
            name="description"
            value={createableEvent.description}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            required={true}
          />
          <p>
            <strong>Beautiful Description:</strong>
          </p>
          {/* <ReactQuill
            value={createableEvent.beautifulDescription}
            onChange={handleHtmlChange}
            modules={modules}
            formats={formats}
            style={{ marginBottom: "15px" }}
          /> */}
          <JoditEditor
            value={createableEvent.beautifulDescription}
            config={editorConfig}
            tabIndex={1}
            onBlur={(newContent) => handleHtmlChange(newContent)}
          />
          <TextField
            disabled={!isEditable}
            label="Expert Image"
            name="expertImage"
            value={createableEvent.expertImage}
            onChange={handleCreateChange}
            fullWidth
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Expert Name"
            name="expertName"
            value={createableEvent.expertName}
            onChange={handleCreateChange}
            fullWidth
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Category"
            name="category"
            value={createableEvent.category}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="Creator"
            name="creator"
            value={createableEvent.creator}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <p>
            <strong>Event Date:</strong>
          </p>
          <MDInput
            type="date"
            fullWidth
            // value={createableEvent.eventDate && formatDateForInput(createableEvent.eventDate)}
            onChange={(e) => handleDateChange("eventDate", e.target.value)}
            margin="normal"
            required={true}
          />
          <p>
            <strong>Start Time:</strong>
          </p>
          <MDInput
            type="datetime-local"
            fullWidth
            // value={
            //   createableEvent.startTime &&
            //   new Date(parseInt(createableEvent.startTime)).toISOString().slice(0, -1)
            // }
            onChange={(e) => handleDateChange("startTime", e.target.value)}
            margin="normal"
            required={true}
          />
          <p>
            <strong>End Time:</strong>
          </p>
          <MDInput
            type="datetime-local"
            fullWidth
            // value={
            //   createableEvent.endTime &&
            //   new Date(parseInt(createableEvent.endTime)).toISOString().slice(0, -1)
            // }
            onChange={(e) => handleDateChange("endTime", e.target.value)}
            margin="normal"
            required={true}
          />
          {/* <TextField
            disabled={!isEditable}
            label="Cost Type"
            name="costType"
            value={createableEvent.costType || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          /> */}
          <Autocomplete
            options={["free", "paid"]}
            value={createableEvent.costType || ""}
            onChange={(event, newValue) => {
              setCreateableEvent({ ...createableEvent, costType: newValue });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Cost Type"
                fullWidth
                margin="normal"
                required={true}
              />
            )}
          />
          <TextField
            disabled={!isEditable}
            label="Cost"
            name="cost"
            type="number"
            value={Number(createableEvent.cost)}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Seats Left"
            name="seatsLeft"
            type="number"
            value={Number(createableEvent.seatsLeft)}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
            required={true}
          />
          <TextField
            disabled={!isEditable}
            label="occurance"
            name="occurance"
            type="number"
            value={createableEvent.occurance}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <Autocomplete
            options={["workshop", "session"]}
            value={createableEvent.type || ""}
            onChange={(event, newValue) => {
              setEditableEvent({ ...createableEvent, type: newValue });
              setUpdatedFields({ ...updatedFields, type: newValue });
              setCreateableEvent({ ...createableEvent, type: newValue });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Type"
                fullWidth
                margin="normal"
                required={true}
              />
            )}
          />
          <Autocomplete
            options={["true", "false"]}
            value={createableEvent.isParent || ""}
            onChange={(event, newValue) => {
              setEditableEvent({ ...createableEvent, isParent: newValue == "true" ? true : false });
              setUpdatedFields({ ...updatedFields, isParent: newValue == "true" ? true : false });
              setCreateableEvent({
                ...createableEvent,
                isParent: newValue == "true" ? true : false,
              });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Is parent"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Autocomplete
            options={["true", "false"]}
            value={createableEvent.isScheduled || ""}
            onChange={(event, newValue) => {
              setEditableEvent({
                ...createableEvent,
                isScheduled: newValue == "true" ? true : false,
              });
              setUpdatedFields({
                ...updatedFields,
                isScheduled: newValue == "true" ? true : false,
              });
              setCreateableEvent({
                ...createableEvent,
                isScheduled: newValue == "true" ? true : false,
              });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Is scheduled"
                fullWidth
                margin="normal"
              />
            )}
          />
          <TextField
            disabled={!isEditable}
            label="Cron"
            name="cron"
            value={createableEvent.cron || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Recording Link"
            name="recordingLink"
            value={createableEvent.recordingLink || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Share Message"
            name="shareMessage"
            value={createableEvent.shareMessage || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Same day Event ID"
            name="sameDayEventId"
            value={createableEvent.sameDayEventId || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Modifier"
            name="modifier"
            value={createableEvent.modifier || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Lang"
            name="lang"
            value={createableEvent.lang || ""}
            onChange={handleCreateChange}
            fullWidth
            margin="normal"
          />
          <Box display="flex" justifyContent="center" marginTop="20px">
            <MDButton
              onClick={() => {
                validateData()
                  .then((res) => {
                    if (confirm("Are you sure you want to Create this event")) handleCreateEvent();
                  })
                  .catch((err) => {
                    alert("Please fill all the required fields");
                  });
              }}
              color="info"
              variant="contained"
            >
              Create Event
            </MDButton>
          </Box>
          {error && !loading && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              Sorry, something went wrong.
            </Alert>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" marginTop="20px">
              <Oval
                visible={true}
                height="35"
                width="35"
                color="#2196F3"
                secondaryColor="#2196F3"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          )}
        </div>
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      {editableEvent && (
        <div>
          <Box display="flex" justifyContent="center" mb={2}>
            <img
              src={editableEvent.coverImage}
              alt="coverImage"
              style={{
                width: "60%",
                borderRadius: "5px",
              }}
            />
          </Box>
          <TextField
            disabled={!isEditable}
            label="Event ID"
            name="id"
            value={editableEvent.id}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            disabled={!isEditable}
            label="Event Name"
            name="eventName"
            value={editableEvent.eventName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Cover Image"
            name="coverImage"
            value={editableEvent.coverImage}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Description"
            name="description"
            value={editableEvent.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
          />
          <p>
            <strong>Beautiful Description:</strong>
          </p>
          {/* <ReactQuill
            value={editableEvent.beautifulDescription}
            onChange={handleHtmlChange}
            style={{ marginBottom: "15px" }}
            modules={modules}
            formats={formats}
          /> */}
          <JoditEditor
            value={editableEvent.beautifulDescription}
            config={editorConfig}
            tabIndex={1}
            onBlur={(newContent) => handleHtmlChange(newContent)}
          />
          <Box display="flex" alignItems="center" gap="8px" marginY="10px">
            <p>
              <strong>Expert:</strong>
            </p>
            <Avatar src={editableEvent.expertImage} alt={editableEvent.expertName} />
            <TextField
              disabled={!isEditable}
              name="expertName"
              value={editableEvent.expertName}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <TextField
            disabled={!isEditable}
            name="expertImage"
            label="Expert Image"
            value={editableEvent.expertImage}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            disabled={!isEditable}
            label="Category"
            name="category"
            value={editableEvent.category}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Creator"
            name="creator"
            value={editableEvent.creator}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <p>
            <strong>Start Time:</strong>
          </p>
          <MDInput
            type="datetime-local"
            fullWidth
            value={formatDateForInput(editableEvent.startTime)}
            onChange={(e) => handleDateChange("startTime", e.target.value)}
            margin="normal"
          />
          <p>
            <strong>End Time:</strong>
          </p>
          <MDInput
            type="datetime-local"
            fullWidth
            value={formatDateForInput(editableEvent.endTime)}
            onChange={(e) => handleDateChange("endTime", e.target.value)}
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Cost Type"
            name="costType"
            value={editableEvent.costType || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Cost"
            name="cost"
            type="number"
            value={Number(editableEvent.cost)}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Seats Left"
            name="seatsLeft"
            type="number"
            value={Number(editableEvent.seatsLeft)}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="occurance"
            name="occurance"
            type="text"
            value={editableEvent.occurance}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="sameDayEventId"
            name="sameDayEventId"
            type="text"
            value={editableEvent.sameDayEventId}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Autocomplete
            options={["workshop", "session"]}
            value={editableEvent.type || ""}
            onChange={(event, newValue) => {
              setEditableEvent({ ...editableEvent, type: newValue });
              setUpdatedFields({ ...updatedFields, type: newValue });
            }}
            renderInput={(params) => (
              <TextField
                disabled={!isEditable}
                {...params}
                label="Type"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="participant-list-content"
              id="participant-list-header"
            >
              <Typography>
                Participants (
                {editableEvent.participantList && editableEvent.participantList.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List dense>
                {editableEvent.participantList &&
                  editableEvent.participantList?.map((participant, index) => (
                    <ListItem key={index} sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ minWidth: "25px", mr: 0.2 }} fontSize={"14px"}>
                        {index}.
                      </Typography>
                      <ListItemText primary={participant} />
                    </ListItem>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>

          <TextField
            disabled={!isEditable}
            label="Recording Link"
            name="recordingLink"
            value={editableEvent.recordingLink || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled={!isEditable}
            label="Meeting Link"
            name="meetingLink"
            value={editableEvent.meetingLink || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            disabled={!isEditable}
            label="Meeting ID"
            name="meetingId"
            value={editableEvent.meetingId || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          {isEditable && (
            <Box display="flex" justifyContent="center" gap={"80px"} marginTop="20px">
              <MDButton
                onClick={() => {
                  if (confirm("Are you sure you want to update this event")) handleUpdateEvent();
                }}
                color="info"
                variant="contained"
              >
                Update Event
              </MDButton>
              <MDButton
                onClick={() => {
                  if (confirm("Are you sure you want to delete this event")) handleDeleteEvent();
                }}
                color="primary"
                variant="contained"
              >
                Delete Event
              </MDButton>
            </Box>
          )}
          {error && !loading && (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              Sorry, something went wrong.
            </Alert>
          )}
          {loading && (
            <Box display="flex" justifyContent="center" marginTop="20px">
              <Oval
                visible={true}
                height="35"
                width="35"
                color="#2196F3"
                secondaryColor="#2196F3"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </Box>
          )}
        </div>
      )}
    </DialogContent>
  );
};

export default EventDetails;
