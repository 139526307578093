import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberGrid from "./grid";
import CallNextButton from "./callNext";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import { setIsLoggedIn } from "context";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { setFixedNavbar } from "context";
import { setMiniSidenav } from "context";
import NotLoggedIn from "layouts/authentication/not-logged-in";

export default function TambolaBoard() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { isLoggedIn } = controller;
  const [alreadyCalledNumbers, setAlreadyCalledNumbers] = useState([]);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [token, setToken] = useState(null);
  const [showTambola, setShowTambola] = useState(false);
  const [time, setTime] = useState(5);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [confetti, setConfetti] = useState(false);
  let timeout;

  const getEventData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${SERVER_URL}/event/getEvent`,
        { id: localStorage.getItem("eventId") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const lt = response.data.event.liveTambola;
      const tc = response.data.event.tambolaNumberCaller;
      const name = response.data.event.eventName;
      setEventName(name);
      if (lt.index != -1) {
        const alreadyCalledNumbersL = tc.slice(0, lt.index);
        setAlreadyCalledNumbers(alreadyCalledNumbersL);
        setCurrentNumber(response.data.event.liveTambola.value);
      }
      setShowTambola(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFinish = () => {
    setCompleted(true);
    setConfetti(true);
  };

  const handleSubmit = async () => {
    if (eventId && eventId.length > 0) {
      localStorage.setItem("eventId", eventId);
      await getEventData();
    } else {
      alert("Please enter a valid event ID");
    }
  };

  useEffect(() => {
    setFixedNavbar(dispatch, false);
    setMiniSidenav(dispatch, true);
    const eventIdL = localStorage.getItem("eventId");
    const tokenL = localStorage.getItem("token");
    if (tokenL) {
      setToken(tokenL);
      setIsLoggedIn(dispatch, true);
    }
    if (eventIdL) {
      setEventId(eventIdL);
      getEventData();
      setShowTambola(true);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }
  if (!showTambola) {
    return (
      <>
        <Helmet>
          <title>Tambola Board</title>
          <link rel="icon" href="assets/images/logo.png" />
        </Helmet>
        <MDBox
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <MDTypography variant="h2">Please enter the event ID</MDTypography>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Enter the Event ID"
              fullWidth
              value={eventId}
              onChange={(e) => setEventId(e.target.value)}
            />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
              Submit
            </MDButton>
          </MDBox>
          {loading && (
            <Oval
              visible={true}
              height="50"
              width="50"
              color="#2196F3"
              secondaryColor="#2196F3"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </MDBox>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>Tambola Board</title>
        <link rel="icon" href="assets/images/logo.png" />
      </Helmet>
      <DashboardLayout>
        <Box>
          <MDBox my={1}>
            <Typography
              variant="h1"
              style={{ fontFamily: "monospace", fontWeight: "bold", textTransform: "uppercase" }}
              textAlign="center"
              color={"white"}
            >
              {eventName ? eventName : "Tambola Board"}
            </Typography>
          </MDBox>
          <MDBox
            mb={10}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "start",
              padding: "10px",
            }}
          >
            <CallNextButton
              currentNumber={currentNumber}
              setCurrentNumber={setCurrentNumber}
              alreadyCalledNumbers={alreadyCalledNumbers}
              setAlreadyCalledNumbers={setAlreadyCalledNumbers}
              eventId={eventId}
              completed={completed}
              setCompleted={setCompleted}
              confetti={confetti}
              setConfetti={setConfetti}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <NumberGrid
                alreadyCalledNumbers={alreadyCalledNumbers}
                currentNumber={currentNumber}
              />
              {!completed && alreadyCalledNumbers.length > 3 && (
                <MDBox sx={{ mt: 2 }} borderRadius="3px">
                  <Button
                    variant="gradient"
                    onClick={() => {
                      if (confirm("Are you sure you want to finish this game?")) handleFinish();
                      else return;
                    }}
                    sx={{
                      minWidth: "150px",
                      bgcolor: "#29BFC2",
                      "&:hover": { bgcolor: "#29BFC299" },
                    }}
                  >
                    <MDTypography variant="h5" color="white">
                      Finish Tambola
                    </MDTypography>
                  </Button>
                </MDBox>
              )}
            </Box>
          </MDBox>
          <Grid
            container
            sx={{
              display: "flex",
            }}
          >
            {alreadyCalledNumbers.map((number, index) => (
              <MDBox
                key={index}
                sx={{
                  bgcolor: "#ffb9b3",
                  borderRadius: "5px",
                }}
                my="2px"
                mx="2px"
              >
                <MDTypography
                  color="white"
                  variant="h4"
                  sx={{
                    padding: "2px 2px",
                    minWidth: "50px",
                    textAlign: "center",
                  }}
                >
                  {number}
                </MDTypography>
              </MDBox>
            ))}
          </Grid>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "start",
            }}
            py={2}
          >
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => navigate("/tambola/getTicket")}
            >
              <MDTypography variant="h5" color="light">
                Get Tickets
              </MDTypography>
            </MDButton>
          </MDBox>
        </Box>
      </DashboardLayout>
    </>
  );
}
