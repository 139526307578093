import { Alert, Autocomplete, Box } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NotLoggedIn from "layouts/authentication/not-logged-in";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Oval } from "react-loader-spinner";

function Payments() {
  const [phone, setPhone] = useState(null);
  const [type, setType] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [controller, dispatch] = useMaterialUIController();

  const { isLoggedIn } = controller;

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setError(false);
  }, [phone, type, paymentDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(false);
      const params = new URLSearchParams();
      if (phone) params.append("phone", phone);
      if (type) params.append("type", type);
      if (paymentDate) params.append("paymentDate", paymentDate);
      const url = `${SERVER_URL}/payments/download?${params.toString()}`;
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response.data;
      downloadCSV(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.log(error);
    }
  };

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }

  return (
    <>
      <Helmet>
        <title>Payments Data</title>
        <link rel="icon" href="assets/images/logo.png" />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <MDBox
            variant="gradient"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Phone"
                    fullWidth
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <Autocomplete
                    // disableClearable
                    fullWidth
                    value={type}
                    options={["contribution", "workshop"]}
                    onChange={(e, n) => {
                      setType(n);
                    }}
                    size="medium"
                    renderInput={(params) => <MDInput {...params} label="Type" fullWidth />}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="date"
                    fullWidth
                    onChange={(e) => {
                      const fullDate = new Date(e.target.value);
                      const unixTime = fullDate.getTime();
                      setPaymentDate(unixTime);
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth type="submit">
                    Download
                  </MDButton>
                </MDBox>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {error && !loading && (
                    <Alert severity="error" style={{ marginTop: "10px" }}>
                      Sorry, something went wrong.
                    </Alert>
                  )}
                  {loading && (
                    <Oval
                      visible={true}
                      height="35"
                      width="35"
                      color="#2196F3"
                      secondaryColor="#2196F3"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  )}
                </Box>
                <MDBox mt={3} mb={1} textAlign="center"></MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Payments;
