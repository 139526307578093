import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function NotLoggedIn() {
  const [time, setTime] = useState(5);
  const navigate = useNavigate();
  let interval;
  let timeout;

  useEffect(() => {
    interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    timeout = setTimeout(() => {
      navigate("/authentication/sign-in");
    }, 5000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirecting...</title>
        <link rel="icon" href="assets/images/logo.png" />
      </Helmet>
      <MDBox
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MDTypography variant="h2">You are not logged in</MDTypography>
        <MDBox my={2}>
          <MDButton variant="gradient" disabled>
            <MDTypography color="error">Redirecting you to sign in page in {time}</MDTypography>
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

export default NotLoggedIn;
