import React, { useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { PropTypes } from "prop-types";
import MDTypography from "components/MDTypography";
import { Oval } from "react-loader-spinner";
import { useWindowSize } from "@react-hook/window-size";
import Confetti from "react-confetti";
import Categories from "./categories";

const data = {
  1: ["Top of the house number 1", "Son of a gun", "At the beginning"],
  2: ["Me and you", "Kaala dhan", "Doctor who"],
  3: ["Happy family"],
  4: ["Knock at the door", "Hum 2 hamare 2"],
  5: ["Symbol of congress", "Punjab da puttar", "Fingers in your hand"],
  6: ["Super sixer", "I'll fix"],
  7: [
    "Lucky no. Seven",
    "One hockey stick",
    "God's in Heaven",
    "days in a week",
    "Colours of rainbow",
  ],
  8: ["Big fat lady or uncle"],
  9: ["You all are mine", "Doctor's time"],
  10: ["A big fat hen"],
  11: ["1 and 1 eleven", "ek or ek gyarah", "o mere yara"],
  12: ["One dozen"],
  13: ["Unlucky for some lucky for me no"],
  14: ["Valentine's Day"],
  15: ["khatra"],
  16: ["Sweet sixteen"],
  17: ["Dancing Queen"],
  18: ["Voting age"],
  19: ["Last of the teens", "End of teens", "Goodbye teens"],
  20: ["Blind 20"],
  21: ["President's salute"],
  22: ["Two little ducks"],
  23: ["You and me"],
  24: ["Two dozen"],
  25: ["Silver Jublee Number"],
  26: ["Republic Day"],
  27: ["Gateway to heaven"],
  28: ["Duck and its mate"],
  29: ["In your prime"],
  30: ["Its middle age"],
  31: ["Time for fun"],
  32: ["Mouth Full", "Buckle my shoe"],
  33: ["All the 3s", "Two little bees"],
  34: ["Dil mange more", "Ask for more", "Lions roar"],
  35: ["Flirty husband and wife"],
  36: ["Popular size", "Three dozens"],
  37: ["Mixed luck"],
  38: ["Oversize", "Christmas cake"],
  39: ["Watch your waistline"],
  40: ["Naughty 40"],
  41: ["Life's begun at 41"],
  42: ["Quit India Movement"],
  43: ["Pain in the knee"],
  44: ["All the fours"],
  45: ["Halfway there"],
  46: ["Up to tricks"],
  47: ["Year of Independence", "Four and seven"],
  48: ["Four dozen", "You are not late"],
  49: ["Rise and shine"],
  50: ["Half a century", "Golden Jublee"],
  51: ["Charity begins at 51"],
  52: ["Pack of cards", "Weeks in a year"],
  53: ["Stuck in a tree"],
  54: ["Clean the floor", "House of bamboo door"],
  55: ["All the fives", "Bunch of 5s"],
  56: ["Pick up sticks"],
  57: ["Mutiny Year"],
  58: ["Make them wait", "Time to retire"],
  59: ["Just retired"],
  60: ["Five dozen"],
  61: ["Bakers bun"],
  62: ["Turn the screw", "Click the two"],
  63: ["Tickle me", "Click the three"],
  64: ["Catch the chor"],
  65: ["Old age pension"],
  66: ["Chakke pe chakka", "All the 6s"],
  67: ["Made in heaven"],
  68: ["Saving grace"],
  69: ["Your place or mine", "Ulta Pulta"],
  70: ["Lucky blind"],
  71: ["Lucky bachelor"],
  72: ["Lucky 2"],
  73: ["Under the tree", "A crutch and a flea"],
  74: ["Lucky chor", "Candy store", "Lucky 4"],
  75: ["Diamond Jublee"],
  76: ["Lucky six"],
  77: ["Two hockey sticks", "hum saat saat hai"],
  78: ["Heaven's gate", "Lucky seth (hindi)"],
  79: ["One more time", "lucky nine"],
  80: ["Gandhi's breakfast"],
  81: ["Corner shot"],
  82: ["Last of the two"],
  83: ["India wins Cricket World Cup", "Time for tea"],
  84: ["Last of the fours", "Seven dozen"],
  85: ["Staying alive", "Grandma"],
  86: ["Last six"],
  87: ["Grandpa"],
  88: ["Two fat ladies or uncle"],
  89: ["All but one", "Nearly there"],
  90: ["Top of the house", "End of the line"],
};

const CallNextButton = ({
  currentNumber,
  setCurrentNumber,
  alreadyCalledNumbers,
  setAlreadyCalledNumbers,
  eventId,
  completed,
  setCompleted,
  confetti,
  setConfetti,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prevNumber, setPrevNumber] = useState(0);
  const [question, setQuestion] = useState(false);
  const [randomRhyme, setRandomRhyme] = useState("");

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (data[currentNumber]) {
      const rhyme = data[currentNumber][Math.floor(Math.random() * data[currentNumber].length)];
      setRandomRhyme(rhyme);
    }
  }, [currentNumber]);

  useEffect(() => {
    const tokenL = localStorage.getItem("token");
    setToken(tokenL);
    setPrevNumber(currentNumber);
  }, []);

  const handleCallNext = async () => {
    setAlreadyCalledNumbers((prev) => [...prev, prevNumber || currentNumber]);
    setIsAnimating(true);
    if (alreadyCalledNumbers.length == 89) {
      setCompleted(true);
      setConfetti(true);
      return;
    }
    try {
      setLoading(true);
      setQuestion(true);
      const response = await axios.post(
        `${SERVER_URL}/admin/tambola/getCallNumber`,
        {
          eventId: eventId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token} `,
          },
        }
      );
      const data = response.data;
      const nextNumber = data.number;
      const alreadyCalledNumbers = data.alreadyCalledNumbers;
      setQuestion(false);
      setLoading(false);
      const randomNumberTimer = setTimeout(() => {
        clearInterval(intervalId);
        setCurrentNumber(nextNumber);
        setPrevNumber(nextNumber || currentNumber);
        setAlreadyCalledNumbers(alreadyCalledNumbers);
        setIsAnimating(false);
      }, 2000);

      let intervalId = setInterval(() => {
        setCurrentNumber(Math.floor(Math.random() * 90) + 1);
      }, 100);

      return () => {
        clearTimeout(randomNumberTimer);
      };
    } catch (error) {
      setLoading(false);
      setQuestion(false);
      console.error("Error fetching next number:", error);
      setIsAnimating(false);
    }
  };

  return (
    <MDBox
      sx={{
        width: "100%",
      }}
    >
      <Confetti width={width * 0.8} height={height} run={confetti} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          {!completed && (
            <MDBox sx={{ mb: 2 }} borderRadius="3px">
              <Button
                variant="gradient"
                onClick={handleCallNext}
                sx={{
                  minWidth: "130px",
                  maxWidth: "130px",
                  bgcolor: "#29BFC2",
                  "&:hover": { bgcolor: "#29BFC299" },
                }}
              >
                <MDTypography variant="h5" color="white">
                  Call Next
                </MDTypography>
              </Button>
            </MDBox>
          )}
          {/* {!completed && alreadyCalledNumbers.length > 20 && (
            <MDBox sx={{ mb: 2 }} borderRadius="3px">
              <Button
                variant="gradient"
                onClick={() => {
                  if (confirm("Are you sure you want to finish this game?")) handleFinish();
                  else return;
                }}
                // color="secondary"
                sx={{
                  minWidth: "150px",
                  bgcolor: "#29BFC2",
                  "&:hover": { bgcolor: "#29BFC299" },
                }}
              >
                <MDTypography variant="h5" color="white">
                  Finish Tambola
                </MDTypography>
              </Button>
            </MDBox>
          )} */}
        </MDBox>
        {loading && (
          <Oval
            visible={true}
            height="50"
            width="50"
            color="#2196F3"
            secondaryColor="#2196F3"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {currentNumber && !loading && !completed && (
            <Box
              sx={{
                border: "1.5px solid gray",
                padding: "5px 8px",
                minWidth: "70px",
                minHeight: "70px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "130px",
                maxWidth: "130px",
                verticalAlign: "center",
                minHeight: "90px",
              }}
            >
              <Typography
                variant="h2"
                color={"#29BFC2"}
                sx={{
                  animation: isAnimating ? "randomNumberAnimation 2s ease-in-out" : "none",
                  "@keyframes randomNumberAnimation": {
                    "0%": {
                      transform: "scale(1)",
                      color: "black",
                    },
                    "50%": {
                      transform: "scale(1.5)",
                      color: "#3b5998",
                    },
                    "100%": {
                      transform: "scale(1)",
                      color: "#eda29b",
                    },
                  },
                  borderRadius: "5px",
                }}
              >
                {currentNumber || ""}
              </Typography>
            </Box>
          )}
          {!completed && (
            <MDBox
              sx={{
                border: "1.5px solid gray",
                padding: "2px 4px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                minWidth: "130px",
                maxWidth: "130px",
                minHeight: "50px",
                verticalAlign: "center",
              }}
            >
              <Typography
                variant="p"
                lineHeight={1}
                color="#29BFC2"
                sx={{
                  textAlign: "center",
                }}
              >
                {!loading && currentNumber && !completed && !question && !isAnimating
                  ? randomRhyme
                  : "???"}
              </Typography>
            </MDBox>
          )}
        </Box>
        {completed && (
          <MDBox
            sx={{
              border: "1.5px solid gray",
              padding: "2px 4px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              minWidth: "130px",
              maxWidth: "130px",
              minHeight: "50px",
              verticalAlign: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h5" color="#29BFC2">
              Tambola Completed
            </Typography>
          </MDBox>
        )}
        <Categories />
      </Box>
    </MDBox>
  );
};

export default CallNextButton;

CallNextButton.propTypes = {
  currentNumber: PropTypes.number,
  setCurrentNumber: PropTypes.func,
  alreadyCalledNumbers: PropTypes.array,
  setAlreadyCalledNumbers: PropTypes.func,
  eventId: PropTypes.string,
  completed: PropTypes.boolean,
  setCompleted: PropTypes.func,
  confetti: PropTypes.boolean,
  setConfetti: PropTypes.func,
};
