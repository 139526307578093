import { useState } from "react";
import { PropTypes } from "prop-types";
import { Box, Checkbox, IconButton, TextField } from "@mui/material";
import { Delete, Edit, Save } from "@mui/icons-material";

function CategoryItem({ todo, updateTodo, deleteTodo, toggleComplete }) {
  const [isTodoEditable, setIsTodoEditable] = useState(false);

  const [todoMsg, setTodoMsg] = useState(todo.title);

  const editTodo = () => {
    updateTodo(todo.id, { ...todo, title: todoMsg });
    setIsTodoEditable(false);
  };

  const toggleCompleted = () => {
    toggleComplete(todo.id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        // borderRadius: 1,
        boxShadow: 1,
        // bgcolor: todo.completed ? "#7b809aA1" : "#ccbed7",
      }}
    >
      <Checkbox
        checked={todo.completed}
        onChange={() => toggleCompleted(todo.id)}
        sx={{ "&:hover": { bgcolor: "transparent" } }}
      />
      <TextField
        fullWidth
        variant="standard"
        value={todoMsg}
        onChange={(e) => setTodoMsg(e.target.value)}
        disabled={!isTodoEditable || todo.completed}
        InputProps={{
          disableUnderline: !isTodoEditable,
          style: {
            textDecoration: todo.completed ? "line-through 1.5px red" : "none",
            fontSize: "17px",
            fontWeight: "bold",
          },
        }}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000",
          },
        }}
      />
      <IconButton
        onClick={() => {
          if (todo.completed) return;

          if (isTodoEditable) {
            editTodo();
          } else setIsTodoEditable((prev) => !prev);
        }}
        disabled={todo.completed}
        size="small"
        sx={{ bgcolor: "background.paper" }}
      >
        {isTodoEditable ? <Save /> : <Edit />}
      </IconButton>
      <IconButton
        onClick={() => deleteTodo(todo.id)}
        size="small"
        sx={{ bgcolor: "background.paper" }}
      >
        <Delete />
      </IconButton>
    </Box>
  );
}

export default CategoryItem;

CategoryItem.propTypes = {
  todo: PropTypes.object,
  updateTodo: PropTypes.func,
  deleteTodo: PropTypes.func,
  toggleComplete: PropTypes.func,
};
