import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMaterialUIController, setIsLoggedIn } from "context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "../../GoComponents/Events";
import NotLoggedIn from "layouts/authentication/not-logged-in";
import Title from "./Title";
import View from "./View";
import { Oval } from "react-loader-spinner";
import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Icon,
} from "@mui/material";
import MDButton from "components/MDButton";
import { Helmet } from "react-helmet";
import EventDetails from "../../GoComponents/Events/EventDetails";
import FilterDialog from "GoComponents/Events/FilterComponent";
import CloseIcon from "@mui/icons-material/Close";

function Events() {
  const [token, setToken] = useState(null);
  const [eventToView, setEventToView] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventDetailsLoading, setEventDetailsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [eventError, setEventError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [controller, dispatch] = useMaterialUIController();
  const [create, setCreate] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [recordingNull, setRecordingNull] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [createTemplate, setCreateTemplate] = useState(false);
  const [createFromTemplate, setCreateFromTemplate] = useState(false);

  const { isLoggedIn } = controller;

  useEffect(() => {
    const rolesString = localStorage.getItem("roles");
    const roles = JSON.parse(rolesString);
    if (roles != null && (roles.includes("ADMIN") || roles.includes("EVENT_MANAGER"))) {
      setIsEditable(true);
    }
  }, []);

  const togglePopup = (event = {}) => {
    setEventError(false);
    setEventDetailsLoading(false);
    setEventToView(event);
    setUpdatedFields({});
    setOpen(!open);
    if (createTemplate) setCreateTemplate(false);
    if (createFromTemplate) setCreateFromTemplate(false);
    if (create) setCreate(!create);
  };

  const toggleFilterPopup = () => setFiltersOpen(!filtersOpen);

  const toggleTemplatesPopup = () => {
    setTemplatesOpen(!templatesOpen);
  };

  useEffect(() => {
    const tokenL = localStorage.getItem("token");
    if (tokenL) {
      setToken(tokenL);
      setIsLoggedIn(dispatch, true);
    }
  }, []);

  useEffect(() => {
    if (templatesOpen) {
      fetchTemplates();
    }
  }, [templatesOpen]);

  useEffect(() => {
    if (token) {
      fetchEvents(currentPage);
    }
  }, [token, currentPage, activeFilter]);

  const fetchEvents = async (page) => {
    try {
      setError(false);
      setLoading(true);
      let url = `${SERVER_URL}/admin/events/getPaginatedEvents?page=${page}`;

      if (activeFilter) {
        if (activeFilter.type === "date") {
          url += `&minDate=${activeFilter.minDate}&maxDate=${activeFilter.maxDate}`;
        } else if (activeFilter.type === "field") {
          url += `&filterField=${activeFilter.field}&filterValue=${encodeURIComponent(
            activeFilter.value
          )}`;
        }
        url += `&recordingNull=${recordingNull}`;
      }
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response.data;
      setEvents(data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error("Error fetching events:", error);
    }
  };

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${SERVER_URL}/admin/events/getTemplates`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setTemplates(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error in fetching templates", error);
    }
  };

  const onCreateTemplate = async (template) => {
    try {
      setLoading(true);
      setError(false);
      setEventError(false);
      const response = await axios.post(`${SERVER_URL}/admin/events/createTemplate`, template, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status == 200) {
        toggleTemplatesPopup();
        fetchTemplates();
      }
      setLoading(false);
      setError(false);
      setEventError(false);
      setOpen(false);
      setCreateTemplate(false);
      setTemplatesOpen(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      setEventError(true);
      setTemplatesOpen(false);
      console.log("Error in creating template", error);
    }
  };

  const onUpdateEvent = async () => {
    try {
      setEventDetailsLoading(true);
      setEventError(false);
      const response = await axios.post(
        `${SERVER_URL}/admin/events/updateEvent/${eventToView.id}`,
        updatedFields,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = response.data;
      if (data.status == "success") {
        togglePopup();
        fetchEvents(currentPage);
      } else {
        alert("Error in updating event");
      }
      setEventDetailsLoading(false);
    } catch (error) {
      setEventError(true);
      setEventDetailsLoading(false);
      console.log("Error in updating event==>", error);
    }
  };

  const onCreateEvent = async (event) => {
    try {
      setEventDetailsLoading(true);
      setEventError(false);
      const response = await axios.post(`${SERVER_URL}/event/create`, event, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.status == 200) {
        togglePopup();
        fetchEvents();
      }
      setEventDetailsLoading(false);
      setCreateFromTemplate(false);
    } catch (error) {
      setEventError(true);
      setEventDetailsLoading(false);
      console.log("Error creating event ==>", error);
    }
  };

  const onDeleteEvent = async () => {
    try {
      setEventDetailsLoading(true);
      setEventError(false);
      const response = await axios.post(
        `${SERVER_URL}/event/delete`,
        {
          id: String(eventToView.id),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status == 200) {
        togglePopup();
        fetchEvents();
      }
      setEventDetailsLoading(false);
    } catch (error) {
      setEventError(true);
      setEventDetailsLoading(false);
      console.log("Error deleting event ==>", error);
    }
  };

  const columns = [
    { Header: "Event Name", accessor: "eventName", width: "30%", align: "left" },
    { Header: "Expert", accessor: "expertName", align: "left" },
    { Header: "Category", accessor: "category", align: "center" },
    { Header: "Start Time", accessor: "startTime", align: "center" },
    { Header: "End Time", accessor: "endTime", align: "center" },
    { Header: "Action", accessor: "view", align: "center" },
  ];

  const rows = events.map((event, index) => ({
    eventName: <Title name={event.eventName} image={event.coverImage} />,
    expertName: <Title name={event.expertName} image={event.expertImage} />,
    category: event.category,
    startTime: new Date(parseInt(event.startTime)).toLocaleString(),
    endTime: new Date(parseInt(event.endTime)).toLocaleString(),
    view: <View onClick={() => togglePopup(event)} text={isEditable ? "View/Edit" : "View"} />,
  }));

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleApplyFilter = (filter) => {
    setActiveFilter(filter);
    setCurrentPage(1);
    fetchEvents(1);
  };

  if (!isLoggedIn) {
    return <NotLoggedIn />;
  }

  return (
    <>
      <Helmet>
        <title>Events Dashboard</title>
        <link rel="icon" href="assets/images/logo.png" />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Dialog open={templatesOpen} onClose={() => toggleTemplatesPopup()}>
            <DialogTitle>Templates</DialogTitle>
            <DialogContent>
              <Grid container>
                {templates.map((template) => (
                  <Card
                    key={template.id}
                    sx={{
                      width: "100%",
                      padding: "10px",
                      margin: "10px",
                      cursor: "pointer",
                      bgcolor: "ButtonFace",
                    }}
                    onClick={() => {
                      setCreateFromTemplate(true);
                      const { templateName, ...rest } = template;
                      togglePopup(rest);
                    }}
                  >
                    <MDTypography variant="h6" color="text" fontWeight="medium">
                      {template.templateName}
                    </MDTypography>
                  </Card>
                ))}
                {loading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Oval
                      visible={true}
                      height="50"
                      width="50"
                      color="#2196F3"
                      secondaryColor="#2196F3"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </Box>
                )}
              </Grid>
              <MDBox
                sx={{
                  justifyContent: "center",
                  width: "100%",
                }}
                display="flex"
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    setCreateTemplate(true);
                    togglePopup();
                  }}
                >
                  Create New Template
                </MDButton>
              </MDBox>
            </DialogContent>
          </Dialog>
          <Dialog open={open} onClose={() => togglePopup()}>
            <DialogTitle>Event Details</DialogTitle>
            <DialogContent>
              <EventDetails
                create={create}
                createTemplate={createTemplate}
                onCreateTemplate={onCreateTemplate}
                createFromTemplate={createFromTemplate}
                eventToView={eventToView}
                onUpdateEvent={onUpdateEvent}
                onCreateEvent={onCreateEvent}
                updatedFields={updatedFields}
                setUpdatedFields={setUpdatedFields}
                onDeleteEvent={onDeleteEvent}
                isEditable={isEditable}
                error={eventError}
                loading={eventDetailsLoading}
              />
            </DialogContent>
          </Dialog>
          <Dialog open={filtersOpen} onClose={() => toggleFilterPopup()}>
            <FilterDialog
              open={filtersOpen}
              setFiltersOpen={setFiltersOpen}
              onClose={() => {
                handleApplyFilter(null);
                setFiltersOpen(false);
                setRecordingNull(false);
              }}
              onApplyFilter={handleApplyFilter}
              activeFilter={activeFilter}
              recordingNull={recordingNull}
              setRecordingNull={setRecordingNull}
            />
          </Dialog>
          <Grid container spacing={4}>
            <MDBox width="100%" display="flex" gap="10px" justifyContent="end">
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  toggleFilterPopup();
                }}
              >
                Filters
              </MDButton>
              {isEditable && (
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    toggleTemplatesPopup();
                  }}
                >
                  Templates
                </MDButton>
              )}
              {isEditable && (
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => {
                    setCreate(true);
                    togglePopup();
                  }}
                >
                  Create Event
                </MDButton>
              )}
            </MDBox>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Events
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Oval
                        visible={true}
                        height="50"
                        width="50"
                        color="#2196F3"
                        secondaryColor="#2196F3"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </Box>
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={true}
                      noEndBorder
                      canSearch={false}
                      pagination={{
                        color: "info",
                        variant: "gradient",
                        currentPage,
                        onChange: handlePageChange,
                      }}
                    />
                  )}
                  {error && !loading && (
                    <Alert severity="error" style={{ marginTop: "10px" }}>
                      Sorry something went wrong.
                    </Alert>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Events;
