import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CategoryItem from "./CategoryItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Categories() {
  const [todo, setTodo] = useState("");
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    const todos = JSON.parse(localStorage.getItem("todos"));
    if (todos && todos.length) {
      setTodos(todos);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);

  const add = (e) => {
    e.preventDefault();
    if (!todo) return;
    addTodo(todo);
  };

  const addTodo = (todo) => {
    setTodos((prevTodo) => {
      return [
        ...prevTodo,
        {
          id: Date.now(),
          title: todo,
          completed: false,
        },
      ];
    });
    setTodo("");
  };

  const updateTodo = (id, todo) => {
    setTodos((prevTodo) => prevTodo.map((prev) => (prev.id === id ? todo : prev)));
  };

  const deleteTodo = (id) => {
    setTodos((prevTodo) => prevTodo.filter((prev) => prev.id !== id));
  };

  const toggleComplete = (id) => {
    setTodos((prevTodo) =>
      prevTodo.map((prev) => (prev.id === id ? { ...prev, completed: !prev.completed } : prev))
    );
  };

  return (
    <>
      <MDBox
        mx={1}
        sx={{
          width: "90%",
        }}
      >
        <Box
          component="form"
          my={2}
          onSubmit={add}
          sx={{ display: "flex", minWidth: "100%", width: "100%" }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Write Category..."
            autoComplete="off"
            value={todo}
            onChange={(e) => setTodo(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              bgcolor: "#29BFC2",
              "&:hover": { bgcolor: "#29BFC299" },
            }}
          >
            <MDTypography
              variant="h8"
              color={"white"}
              sx={{
                color: "white",
              }}
            >
              Add
            </MDTypography>
          </Button>
        </Box>
        <Grid>
          {todos.map((todo) => (
            <Grid item xs={12} key={todo.id}>
              <CategoryItem
                todo={todo}
                updateTodo={updateTodo}
                toggleComplete={toggleComplete}
                deleteTodo={deleteTodo}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </>
  );
}

export default Categories;
