import React from "react";
import { Box } from "@mui/material";
import MDTypography from "components/MDTypography";

// eslint-disable-next-line react/prop-types
const View = ({ onClick, text }) => {
  return (
    <Box
      variant="contained"
      lineHeight={1}
      textAlign="left"
      onClick={onClick}
      sx={{ cursor: "pointer" }} // Sets the cursor to pointer
    >
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{ textDecoration: "underline" }} // Underlines the text
      >
        {text}
      </MDTypography>
    </Box>
  );
};

export default View;
