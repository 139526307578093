import { Alert, Autocomplete, Avatar, Box, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { Title } from "chart.js";
import View from "layouts/events/View";
import DataTable from "GoComponents/Events";

function DownloadUserByDateOfJoining() {
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!date) {
      setError(true);
      return;
    }
    try {
      setError(false);
      setLoading(true);
      const response = await axios.get(`${SERVER_URL}/user/download?dateOfJoining=${date}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = response.data;
      downloadCSV(data);
      //   setUserData(data.user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSignIn}>
              <MDBox mb={2}>
                <MDInput
                  type="date"
                  fullWidth
                  onChange={(e) => {
                    const fullDate = new Date(e.target.value);
                    const unixTime = fullDate.getTime();
                    setDate(unixTime);
                  }}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Download
                </MDButton>
              </MDBox>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {error && !loading && (
                  <Alert severity="error" style={{ marginTop: "10px" }}>
                    Sorry, something went wrong.
                  </Alert>
                )}
                {loading && (
                  <Oval
                    visible={true}
                    height="35"
                    width="35"
                    color="#2196F3"
                    secondaryColor="#2196F3"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                )}
              </Box>
              <MDBox mt={3} mb={1} textAlign="center"></MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      {error && (
        <Box>
          <Typography color={"red"}>
            Sorry, Either no users found or something went wrong
          </Typography>
        </Box>
      )}
    </>
  );
}

export default DownloadUserByDateOfJoining;
